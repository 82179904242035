@import "../../styles/constants";

.el {
  display: block;
  position: relative;
  margin: 0 1rem;
}

.el h1 {
  padding: 2em 0 0.5em;
}

.quickModal {
  padding: 2.5rem;
}

.empty {
  background: $backgroundLight;
  text-align: center;
  color: $textTint;
  font-size: $fontSizeSmall;
  padding: 2.5rem;
}

.open,
.closed {
  background: $backgroundLight;
}

.openContent {
  border-bottom: 1px solid $backgroundDark;
}

.row.rowHeader {
  background: $brandBlue;
  color: #fff;
  padding-bottom: 1px;
}

.rowQuarter {
  background: $backgroundLighter;
  color: $textTint;
}

.rowData {
  background: $backgroundLight;
  cursor: pointer;
}

.rowData:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
}

.rowInfo:after {
  background: $brandBlue;
}

.rowAlert:after {
  background: $brandRed;
}

.row > *,
.innerRowHeader > *,
.innerRow > *,
.materialRow > * {
  display: block;
  flex: 1 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3;
  padding-right: 1rem;
}

.row > :empty:after {
  content: "—";
  color: $textTint;
}

.row img {
  width: 1em;
  height: 1em;
  opacity: 0.5;
  transform: translatey(0.125rem);
  margin: 0 0.5rem 0 0;
}

.innerRow img {
  width: 1em;
  height: 1em;
  opacity: 0.5;
  transform: translatey(0.125rem);
  transition: opacity 0.2s ease-out;
  margin: 0 0.5rem 0 0;
}

.rowHeader svg {
  pointer-events: none;
}

.cellButton {
  background-color: transparent;
  outline: none;
}

.cellButton img {
  transition: opacity 100ms ease;
}

.sort {
  transition: color 100ms ease;
}

.sort:focus,
.sort:hover,
.sortActive {
  color: #fff;
}

.cellCount {
  color: $textTint;
}

.cellAction {
  line-height: 1;
  width: calc(100% + 0.625rem);
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.cellTruncateOff {
  white-space: normal;
  text-overflow: clip;
  overflow: hidden;
}

.cellTruncate {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.levelMax {
  color: $levelMax;
}

.levelHigh {
  color: $levelHigh;
}

.levelLow {
  color: $levelLow;
}

.levelMin {
  color: $levelMin;
}

.levelEmpty {
  color: $levelEmpty;
}

.lblToggle,
.rowOpen .lblToggle {
  display: block;
  font-weight: 700;
  font-family: monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  right: 30px;
  width: 10px;
  margin-top: -3.1rem;
  position: absolute;
  padding: 1rem;
}

.lblToggle::before {
  content: " ";
  border: solid #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: rotate(45deg) translateY(-2px);
  transition: transform 0.2s ease-out;
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.rowOpen .collapsibleContent {
  display: block;
  max-height: 1000px;
  overflow-y: scroll;
}

.collapsibleContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  background: #3a3c49;
}

.collapsibleContent .contentInner {
  max-width: 1280px;
  margin: 2px auto;
  padding: 0.5rem 1rem;
}

.rowOpen .lblToggle::before {
  transform: rotate(225deg) translateX(-3px);
}

.rowOpen .lblToggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdownContentContainer {
  position: relative;
}

.createButton {
  color: #fff;
  padding: 1em 0;
  text-decoration: underline;
  display: inline-block;
}

.editDetailsButton {
  color: #fff;
  margin: 30px 0 10px;
}

.pelle {
}
.actionColumn {
  text-align: right;
}

.indentedRow {
  margin-left: 30px;
}

.unIndentedRow {
  margin-left: -30px;
}

.row,
.innerRow {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  font-size: $fontSizeSmall;
  border-bottom: 1px solid $backgroundDark;
  background: #484b5e;
  user-select: none;
  padding: 0 0 0 1rem;
}

.rowOpen .row,
.row:focus,
.row:hover,
.innerRow:focus,
.innerRow:hover,
.materialRow:focus,
.materialRow:hover {
  background: #6d7081;
  outline: none;
}

.materialRow,
.innerRowHeader {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  font-size: $fontSizeSmall;
  border-bottom: 1px solid $backgroundDark;
  background: #3a3c49;
  user-select: none;
  padding: 0 0 0 1rem;
}

.innerRow img:hover,
.cellButton:focus img,
.cellButton:hover img,
.sort img {
  opacity: 1;
}

@supports (position: sticky) or (position: -webkit-sticky) {
  @media (min-width: 1000px) {
    .header {
      position: sticky;
      position: -webkit-sticky;
      z-index: 10;
      top: calc(3.5rem - 1px);
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-width: 550px;
  min-height: 150px;
  background-color: #3a3c49;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editModal {
  align-items: flex-start;
}

.editForm {
  max-width: 20em;
}

.modal p {
  margin: 10px 0 10px 0;
  flex: 0 0 100%;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modalButton {
  height: 2.5em;
  color: white;
  margin: 0;
  width: 150px;
}

.modalButton:first-of-type {
  margin-right: 20px;
}

.label {
  pointer-events: none;
  z-index: 1;
  top: 0.625rem;
  left: 0.75rem;
  font-size: 0.75rem;
  color: hsla(0, 0%, 100%, 0.625);
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  height: 2.5em;
  background-color: #595c79;
  padding: 0.125rem 0.75rem;
  font-size: 0.875rem;
  outline: none;
  border-radius: 3px;
  border: 1px solid #626674;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
  margin-bottom: 20px;
}

.inboundOrOutbound {
  text-transform: capitalize;
}

.subHeader {
  background-color: #202229;
  position: fixed;
  top: 59px;
  left: 0;
  z-index: 2;
  width: 100%;
}

.scrollableRows {
  margin-top: 95px;
}

.search {
  color: black;
  border-radius: 20px;
  margin: 4px 0 4px 4px;
  font-size: 14px;
  padding: 10px;
  width: 300px;
}

.highlight {
  font-weight: bold;
  background-color: $brandBlue;
}

.permissionGroup {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.materialPermission {
  padding-left: 1rem;
}

.userPermissions,
.terminalPermission {
  margin-top: 1rem;
  margin-bottom: 2rem;

  padding-left: 1rem;

  header {
    font-size: 0.75rem;
    color: hsla(0, 0%, 100%, 0.625);
    margin-bottom: 6px;
    margin-top: 14px;
  }

  ul {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    font-size: $fontSizeSmall;
    user-select: none;
  }
  .email {
    color: hsla(0, 0%, 100%, 0.625);
    margin: 0 0.5rem;
  }
}

.permission {
  color: hsla(0, 0%, 100%, 0.625);
}
