.header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #202229;
}

@supports (position: sticky) or (position: -webkit-sticky) {
  @media (min-width: 1000px) {
    .header {
      position: sticky;
      position: -webkit-sticky;
      z-index: 10;
      top: calc(3.5rem - 1px);
    }
  }
}

.subtitle {
  font-size: 0.875rem;
  margin-top: 5px;
}

.section {
  padding: 0 1em;

  > h2:first-child {
    padding: 2em 0 1em;
  }
}
