@import "../styles/constants";

.el {
  display: block;
  position: relative;
  margin: 0 1rem;
}

.content {
  background-color: #383c4a;
  margin: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  padding: 100px;
}

.quickModal {
  padding: 2.5rem;
}

.label {
  pointer-events: none;
  z-index: 1;
  top: 0.625rem;
  left: 0.75rem;
  font-size: 0.75rem;
  color: hsla(0, 0%, 100%, 0.625);
  margin-bottom: 10px;
}

.input {
  display: block;
  color: white;
  width: 100%;
  height: 2.5em;
  background-color: #595c79;
  padding: 0.125rem 0.75rem;
  font-size: 0.875rem;
  outline: none;
  border-radius: 3px;
  border: 1px solid #626674;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
  margin: 10px 0 20px 0;
}

.checkbox {
  appearance: none;
  display: inline-block;
  $size: 1rem;
  width: $size;
  height: $size;
  border: 1px solid white;
  vertical-align: text-bottom;
  margin: 0.25rem;
  text-align: center;

  &:checked {
    background: white;
    &::before {
      content: "✔";
      color: #595c79;
    }
  }
}

.select {
  margin: 10px 0 20px;
}

.inputForm {
  width: 400px;
}

.button {
  height: 2.5em;
  color: white;
  margin-right: 1em;
}

.inputButton {
  width: calc(50% - 10px);
  height: 2.5em;
  color: white;
}

.inputButton:first-of-type {
  margin-right: 20px;
  background-color: #595c79;
}

.error {
  border: 1px red solid !important;
}
