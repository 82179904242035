$fontSizeSmaller: 0.75rem;
$fontSizeSmall: 0.875rem;
$fontSizeLarge: 1.25rem;
$fontSizeLarger: 1.5rem;

$brandRed: #ec6c61;
$brandYellow: #fce486;
$brandOrange: #f5b349;
$brandGreen: #94be79;
$brandBlue: #8f9ad5;

$brandRedTint: mix(white, $brandRed, 20%);
$brandYellowTint: mix(white, $brandYellow, 20%);
$brandOrangeTint: mix(white, $brandOrange, 20%);
$brandGreenTint: mix(white, $brandGreen, 20%);
$brandBlueTint: mix(white, $brandBlue, 20%);
$brandBlueShade: mix(black, $brandBlue, 20%);
$brandBlueOverlay: rgba($brandBlue, 0.5);

$backgroundLighter: #464b5c;
$backgroundLight: mix(black, $backgroundLighter, 20%);
$backgroundDark: mix(black, $backgroundLighter, 40%);
$backgroundDarker: mix(black, $backgroundLighter, 55%);

$backgroundLightTint: mix(white, $backgroundLight, 2.5%);
$backgroundLighterTint: mix(white, $backgroundLighter, 2.5%);
$backgroundLightOverlayMin: rgba($backgroundLight, 0.5);
$backgroundLightOverlayMax: rgba($backgroundLight, 0.95);
$backgroundDarkerOverlayMin: rgba($backgroundDarker, 0.5);
$backgroundDarkerOverlayMax: rgba($backgroundDarker, 0.95);

$textTint: rgba(255, 255, 255, 0.625);
$textShade: rgba(0, 0, 0, 0.75);
$borderTint: mix(white, $backgroundLighter, 15%);

$levelMax: $brandRed;
$levelHigh: $brandOrange;
$levelLow: $brandYellow;
$levelMin: $brandRed;
$levelEmpty: $brandRed;
