@import "../../styles/constants";

.text {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  margin: 1rem;
  pointer-events: none;
  font-size: $fontSizeSmall;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  background: $backgroundDarkerOverlayMax;
  white-space: nowrap;
}

.textRight {
  transform: translatex(-100%);
  margin-left: -0.5rem;
}

.textAnimated {
  animation: fade-in 150ms 150ms ease both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
