@import "../../styles/constants";

.splashPage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.splashPage div {
  font-size: 48px;
  margin-top: 100px;
}

.cargoToast {
  font-family: "cargo-din-01", sans-serif;
  text-align: center;
}
