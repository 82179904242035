@import "../../styles/constants";

.el {
  display: block;
  position: relative;
  margin: 0 1rem;
}

.quickModal {
  padding: 2.5rem;
}

@supports (position: sticky) or (position: -webkit-sticky) {
  @media (min-width: 1000px) {
    .header {
      position: sticky;
      position: -webkit-sticky;
      z-index: 10;
      top: calc(3.5rem - 1px);
    }
  }
}

.empty {
  padding: 2.5rem;
  background: $backgroundLight;
  text-align: center;
  color: $textTint;
  font-size: $fontSizeSmall;
}

.open,
.closed {
  background: $backgroundLight;
}

.open {
  position: relative;
  z-index: 11;
}

.openContent {
  border-bottom: 1px solid $backgroundDark;
}

.row {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  font-size: $fontSizeSmall;
  padding: 0 0 0 1rem;
  border-bottom: 1px solid $backgroundDark;
  background: $backgroundLight;
  user-select: none;
}

.open .row,
.row:focus,
.row:hover {
  background: $backgroundLighter;
  outline: none;
}

.rowHeader {
  background: $backgroundLighter;
  color: $textTint;
  padding-bottom: 1px;
}

.rowQuarter {
  background: $backgroundLighter;
  color: $textTint;
}

.rowData {
  background: $backgroundLight;
  cursor: pointer;
}

.rowData:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
}

.rowInfo:after {
  background: $brandBlue;
}

.rowAlert:after {
  background: $brandRed;
}

.row > * {
  display: block;
  flex: 1 0 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3;
  padding-right: 1rem;
}

.row > *:empty:after {
  content: "—";
  color: $textTint;
}

@media (max-width: 1600px) {
  .row > .forWideScreen {
    display: none !important;
  }
}

.row img {
  width: 1em;
  height: 1em;
  margin: 0 0.5rem 0 0;
  opacity: 0.5;
  transform: translatey(0.125rem);
}

.rowHeader svg {
  pointer-events: none;
}

.cellButton {
  background-color: transparent;
  outline: none;
}

.cellButton img {
  transition: opacity 100ms ease;
}

.cellButton:focus img,
.cellButton:hover img {
  opacity: 1;
}

.cellLast img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0.25rem;
  right: 1rem;
  margin: auto;
  transition: opacity 100ms ease;
}

.open .cellLast img {
  transform: rotate(180deg) translatey(-0.125rem);
}

.sort {
  transition: color 100ms ease;
}

.sort:focus,
.sort:hover,
.sortActive {
  color: white;
}

.sort img {
  opacity: 1;
}

.cellCount {
  color: $textTint;
}

.cellAction {
  line-height: 1;
  width: calc(100% + 0.625rem);
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.cellTruncateOff {
  white-space: normal;
  text-overflow: clip;
  overflow: hidden;
}

.cellTruncate {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.levelMax {
  color: $levelMax;
}

.levelHigh {
  color: $levelHigh;
}

.levelLow {
  color: $levelLow;
}

.levelMin {
  color: $levelMin;
}

.levelEmpty {
  color: $levelEmpty;
}

.lblToggle,
.rowOpen .lblToggle {
  display: block;
  font-weight: 700;
  font-family: monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  right: 30px;
  width: 10px;
  margin-top: -3.1rem;
  position: absolute;
  padding: 1rem;
}

.lblToggle::before {
  content: " ";
  border: solid #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: rotate(45deg) translateY(-2px);
  transition: transform 0.2s ease-out;
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.rowOpen .collapsibleContent {
  display: block;
  max-height: 2000px;
  overflow-y: scroll;
}

.collapsibleContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  background: #3a3c49;
}

.collapsibleContent .contentInner {
  max-width: calc((300px + 22px) * 3 + 2rem);
  margin: 2px auto;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.rowOpen .lblToggle::before {
  transform: rotate(225deg) translateX(-3px);
}

.rowOpen .lblToggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rowHeader {
  background: $brandBlue;
  color: #fff;
  padding-bottom: 1px;
}

.search {
  color: black;
  border-radius: 20px;
  margin: 4px 0 4px 4px;
  font-size: 14px;
  padding: 10px;
  width: 300px;
}

.highlight {
  font-weight: bold;
  background-color: $brandBlue;
}

.addRowButton {
  margin-top: 33px;
}

.portSelect {
  background-color: $backgroundLight;
}

.label {
  pointer-events: none;
  z-index: 1;
  top: 0.625rem;
  left: 0.75rem;
  font-size: 0.75rem;
  color: hsla(0, 0%, 100%, 0.625);
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  height: 2.5em;
  background-color: #595c79;
  padding: 0.125rem 0.75rem;
  font-size: 0.875rem;
  outline: none;
  border-radius: 3px;
  border: 1px solid #626674;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
  margin: 10px 0 20px 0;
  min-width: 300px;
}

.fieldset {
  min-width: 300px;
  display: inline-block;
  border: none;
  margin-right: 20px;
  position: relative;
}

.companyRow {
  background-color: #595c79;
  //width: 300px;
  display: block;
  border-bottom: 1px solid black;
  line-height: 1rem;
  padding: 10px;
  font-size: 0.875rem;
}

.companies {
  margin-top: 10px;
}

.companiesRow {
  display: block;
}

.companiesList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.inboundOutbound {
  font-size: 0.75rem;
}

.permission {
  color: hsla(0, 0%, 100%, 0.625);
}

.companyContainer {
  min-width: 300px;
}

.terminals,
.materials {
  & > div {
    margin-top: 0.5rem;
    padding-left: 1rem;
  }
  margin-bottom: 1rem;
}

.userPermissions {
  margin-top: 1rem;
  padding-left: 1rem;

  header {
    font-size: 0.75rem;
    color: hsla(0, 0%, 100%, 0.625);
    margin-bottom: 6px;
    margin-top: 14px;
  }
}

.inputButton {
  height: 2.5em;
  color: white;
  margin: 20px 0 60px 0;
  width: 200px;
  float: right;
}

.subHeader {
  background-color: #202229;
  position: fixed;
  top: 59px;
  left: 0;
  z-index: 2;
  width: 100%;
}

.scrollableRows {
  margin-top: 185px;
}
