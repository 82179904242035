@import "../../styles/constants";

.button {
  height: 2.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background: $brandBlue;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  font-size: $fontSizeSmall;
  transition: background 100ms ease;
  color: $textShade;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button.loading {
  pointer-events: auto !important;
  cursor: wait !important;
}

.button.small {
  height: 2rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.button.smaller {
  height: 1.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.button:not(:disabled):focus {
  text-decoration: underline;
}

.button:not(:disabled):focus,
.button:not(:disabled):hover {
  background: $brandBlueTint;
}

.button.link {
  background: transparent !important;
  padding-left: 0;
  padding-right: 0;
  color: $brandBlue;
  font-size: 1rem;
}

.button.link:not(:disabled):focus,
.button.link:not(:disabled):hover {
  text-decoration: underline;
}

.button.secondary {
  background: $backgroundLighter;
  color: white;
}

.button.secondary:not(:disabled):focus,
.button.secondary:not(:disabled):hover {
  background: $backgroundLighterTint;
}

.button.overlay {
  background: $backgroundLightOverlayMin;
  color: white;
}

.button.overlay:not(:disabled):focus,
.button.overlay:not(:disabled):hover {
  background: $backgroundLightOverlayMin;
  text-decoration: none;
}

.button.red {
  background: $brandRed;
}

.button.red:not(:disabled):focus,
.button.red:not(:disabled):hover {
  background: $brandRedTint;
}

.button.green {
  background: $brandGreen;
}

.button.green:not(:disabled):focus,
.button.green:not(:disabled):hover {
  background: $brandGreenTint;
}

.group > * {
  margin: 0 1px 0 0;
}

.group > :first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group > :last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group > :not(:first-child):not(:last-child) {
  border-radius: 0;
}
