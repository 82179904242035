@import "../../styles/constants";

.el {
  height: 3.5rem;
}

.bar {
  position: absolute;
  z-index: 1002;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
  line-height: 1;
  background: $backgroundDarker;
  user-select: none;
  min-width: 1000px;
}

@media (min-width: 1000px) {
  .bar {
    position: fixed;
  }
}

@import "../../styles/constants";

.logo {
  display: block;
}

.logo a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0.625rem 1rem;
  background: transparent !important;
}

.logo img {
  display: block;
  height: 100%;
  width: auto;
}

.nav {
  position: absolute;
  top: 0;
  left: 3.75rem;
}

.link {
  display: inline-block;
  position: relative;
  height: 3.5rem;
  padding: 1.25rem 0.875rem;
  line-height: 1;
  transition: color 100ms ease;
  cursor: pointer;
}

.link:focus,
.link:hover {
  outline: none;
  color: $textTint;
}

.subLink {
  display: none;
  position: absolute;
  left: 5px;
  top: 60px;
  width: 200px;
  height: 34px;
  z-index: 2000;
  background: #202229;
  padding: 10px;
  font-size: 0.85rem;
}

.subLink2 {
  display: none;
  position: absolute;
  left: 200px;
  top: 60px;
  width: 200px;
  height: 34px;
  z-index: 2000;
  background: #202229;
  padding: 10px;
  font-size: 0.85rem;
}

.link:hover + .subLink {
  display: block;
}

.link:hover + .subLink2 {
  display: block;
}

.subLink:hover {
  display: block;
}

.subLink2:hover {
  display: block;
}

.link.active {
  color: white !important;
}

.link.active:after {
  content: "";
  display: block;
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: 0;
  height: 3px;
  background: $brandBlue;
}

.alertWrapper {
  @extend .link;
  cursor: default;
  font-size: 0.8rem;
  position: fixed;
  right: 0;

  img {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}
