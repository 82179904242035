@font-face {
  font-family: "cargo-din-01";
  src: url("/assets/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
  src: url("/assets/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix")
      format("eot"),
    url("/assets/fonts/de1216be-00c2-43c0-9c45-3e7f925eb519.woff2")
      format("woff2"),
    url("/assets/fonts/bc176270-17fa-4c78-a343-9fe52824e501.woff")
      format("woff"),
    url("/assets/fonts/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "cargo-din-01";
  src: url("/assets/fonts/3ab2ca1d-35c8-4471-9c57-04cb66b9cffa.eot?#iefix");
  src: url("/assets/fonts/3ab2ca1d-35c8-4471-9c57-04cb66b9cffa.eot?#iefix")
      format("eot"),
    url("/assets/fonts/26f280d7-8bd0-4192-90f1-3c3cb4cde341.woff2")
      format("woff2"),
    url("/assets/fonts/e1c997f3-fb27-4289-abc6-5ef059e51723.woff")
      format("woff"),
    url("/assets/fonts/0b3b140c-bdbe-488f-8976-992b4104de0c.ttf")
      format("truetype");
  font-weight: 200;
  font-style: italic;
}
