@import "../../styles/constants";

.loadingPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: none;
}

.loadingInline {
  position: relative;
  padding-bottom: 56.25%;
}

.loadingOverlay {
  background: rgba($backgroundDark, 0.25);
  pointer-events: auto;
}

.loadingSpinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  margin: auto;
  transform: translate3d(0, 0, 0);
}

.loadingSpinner .hide {
  display: block;
  text-indent: -999rem;
  opacity: 0;
}

.loadingSpinner div {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: transparent !important;
  animation: animation-rotate 2s linear infinite both;
  will-change: transform;
}

.loadingSpinner div:nth-child(1) {
  width: 4rem;
  height: 4rem;
}

.loadingSpinner div:nth-child(2) {
  width: 2rem;
  height: 2rem;
  animation-direction: reverse;
  border-color: $brandBlue;
}

@keyframes animation-rotate {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
