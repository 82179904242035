@import "../../styles/constants";

.el {
  padding: 10%;
  line-height: 1.5;
}

.info {
  display: block;
  color: $brandRed;
}

.buttons {
  display: block;
  margin: 2.25rem 0 2.75rem 0;
}

.error {
  display: block;
  white-space: pre-wrap;
  padding-top: 2.5rem;
  border-top: 1px solid $borderTint;
  font-size: $fontSizeSmall;
  color: $textTint;
}
