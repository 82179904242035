@import "../../styles/constants";

.el {
  display: block;
  position: relative;
  margin: 0 1rem;
}

.content {
  background-color: #383c4a;
}

.quickModal {
  padding: 2.5rem;
}

.label {
  pointer-events: none;
  z-index: 1;
  top: 0.625rem;
  left: 0.75rem;
  font-size: 0.75rem;
  color: hsla(0, 0%, 100%, 0.625);
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  height: 2.5em;
  background-color: #595c79;
  padding: 0.125rem 0.75rem;
  font-size: 0.875rem;
  outline: none;
  border-radius: 3px;
  border: 1px solid #626674;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
  margin: 10px 0 20px 0;
}

.inputForm {
  width: 400px;
}

.inputButton {
  width: calc(50% - 10px);
  height: 2.5em;
  color: white;
}

.inputButton:first-of-type {
  margin-right: 20px;
  background-color: #595c79;
}

.actionColumn {
  text-align: right;
}

.actionColumn img {
  width: 1em;
  height: 1em;
  opacity: 0.5;
  transform: translatey(0.125rem);
  transition: opacity 0.2s ease-out;
  margin: 0 0.5rem 0 0;
}

.actionColumn img:hover {
  opacity: 1;
}

.lblToggle,
.rowOpen .lblToggle {
  display: block;
  font-weight: 700;
  font-family: monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  right: 30px;
  width: 10px;
  margin-top: -3.4rem;
  position: absolute;
  padding: 1rem;
}

.lblToggle::before {
  content: " ";
  border: solid #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: rotate(45deg) translateY(-2px);
  transition: transform 0.2s ease-out;
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.rowOpen .collapsibleContent {
  display: block;
  max-height: 1000px;
  overflow-y: scroll;
}

.collapsibleContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  background: #3a3c49;
}

.collapsibleContent .contentInner {
  max-width: 1280px;
  margin: 2px auto;
  padding: 0.5rem 1rem;
}

.rowOpen .lblToggle::before {
  transform: rotate(225deg) translateX(-3px);
}

.rowOpen .lblToggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.row > *,
.innerRowHeader > *,
.innerRow > *,
.materialRow > * {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 3.3;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  position: relative;
  font-size: 0.875rem;
  border-bottom: 1px solid #2a2d37;
  background: #484b5e;
  user-select: none;
  padding: 0 1rem 0 1rem;
}

.row > :empty:after {
  content: "—";
  color: $textTint;
}

.row img {
  width: 1em;
  height: 1em;
  opacity: 0.5;
  transform: translatey(0.125rem);
  margin: 0 0.5rem 0 0;
}

.innerRow img {
  width: 1em;
  height: 1em;
  opacity: 0.5;
  transform: translatey(0.125rem);
  transition: opacity 0.2s ease-out;
  margin: 0 0.5rem 0 0;
}

.rowHeader svg {
  pointer-events: none;
}

.empty {
  background: $backgroundLight;
  text-align: center;
  color: $textTint;
  font-size: $fontSizeSmall;
  padding: 2.5rem;
}

.open,
.closed {
  background: $backgroundLight;
}

.collapsibleContent.open {
  display: block;
  max-height: 1000px;
  overflow-y: scroll;
}

.indentedRow {
  margin-left: 30px;
  max-width: 400px;
}

.innerRow {
  display: flex;
  position: relative;
  font-size: $fontSizeSmall;
  background: #484b5e;
  user-select: none;
}

.rowOpen .row,
.row:focus,
.row:hover,
.innerRow:focus,
.innerRow:hover,
.materialRow:focus,
.materialRow:hover {
  background: #6d7081;
  outline: none;
}

.moduleContent {
  padding: 50px 50px 120px 50px;
  display: flex;
  justify-content: flex-start;
}

.saveButtons {
  display: flex;
  padding: 50px;
  justify-content: flex-end;
}

.saveButtons button {
  margin-right: 20px;
  width: 150px;
}

.select {
  width: 300px;
  border-radius: 3px;
}

.moduleDetail {
  display: flex;
}

.materialColumn {
  margin-right: 50px;
  min-width: 500px;
  display: block;
}

.moduleSelect {
  width: 100px;
  margin: 5px;
}

.materialHeader {
  margin-bottom: 10px;
}

.rowTitle {
  width: 30%;
  flex: auto;
}

.rowLabel {
  width: 15%;
  flex: auto;
}

.rowSelect {
  flex: auto;
  padding: 0;
}

.moduleLabel {
  padding-right: 0px;
}

.comment {
  padding-left: 10px;
  padding-top: 16px;
}

.link {
  padding-left: 10px;
  color: $brandBlueTint;
  text-decoration: underline;
  cursor: pointer;
}

.alertWrapper {
  cursor: default;
  font-size: 0.9rem;

  img {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}
